<template>
  <div>
    <v-img :src="background" height="44vh">
      <div class="d-flex flex-column fill-height justify-end">
        <div class="overlay-banner">
          <v-container class="fill-height py-16">
            <div class="d-flex flex-md-row flex-column">
              <div class="d-flex align-center fill-height">
                <back-button class="mr-2" absolute right />

                <div v-if="!!title" class="d-flex align-center">
                  <v-icon v-text="'$hammer2'" left large />

                  <div class="text-h5 text-md-h4 text-xl-h3 font-weight-bold">
                    <span class="saira">{{ title }} </span>
                  </div>
                </div>
              </div>

              <div v-if="!!subtitle" class="d-flex align-center px-6">
                <v-img
                  v-if="$vuetify.breakpoint.mdAndUp"
                  :src="require('@/assets/utils/divider.png')"
                  class="mr-6"
                  max-width="10vw"
                />

                <div class="text-h6 text-md-h5 text-xl-h4 font-weight-regular">
                  <span class="saira">{{ subtitle }} </span>
                </div>
              </div>
            </div>
          </v-container>
        </div>
      </div>
    </v-img>

    <div class="relative d-flex justify-center">
      <v-container class="absolute float-container">
        <div class="d-flex px-4">
          <slot />
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    background: {
      required: true,
    },

    title: {
      type: String,
      default: "",
    },

    subtitle: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.overlay-banner {
  background-color: rgba(0, 0, 0, 0.5);
}

.float-container {
  z-index: 1;
  top: -32px;
}
</style>
