import Vue from "vue";
import VueRouter from "vue-router";
import beforeEach from "./beforeEach";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "Home",
    meta: { preventAuth: true },
    component: () => import("../views/home/Home.vue"),
  },
  {
    path: "/main",
    name: "Main",
    meta: { requiredAuth: true },
    component: () => import("../views/main/Main.vue"),
  },
  {
    path: "/register",
    name: "Register",
    meta: { requiredAuth: true },
    component: () => import("../views/register/Register.vue"),
  },
  {
    path: "/account",
    name: "Account",
    meta: { requiredAuth: true },
    component: () => import("../views/account/Account.vue"),
    children: [
      {
        path: "profile",
        name: "My Profile",
        alias: "",
        meta: { requiredAuth: true },
        component: () => import("../views/account/profile/Profile.vue"),
        children: [
          {
            path: "avatar",
            alias: "",
            name: "Avatar",
            meta: { requiredAuth: true },
            component: () =>
              import("../views/account/profile/children/Avatar.vue"),
          },
          {
            path: "personal-data",
            name: "Personal Data",
            meta: { requiredAuth: true },
            component: () =>
              import("../views/account/profile/children/PersonalData.vue"),
          },
          {
            path: "school-data",
            name: "School Data",
            meta: { requiredAuth: true },
            component: () =>
              import("../views/account/profile/children/SchoolData.vue"),
          },
          {
            path: "security",
            name: "Security",
            meta: { requiredAuth: true },
            component: () =>
              import("../views/account/profile/children/Security.vue"),
          },
          {
            path: "documents",
            name: "Documents",
            meta: { requiredAuth: true },
            component: () =>
              import("../views/account/profile/children/Documents.vue"),
          },
        ],
      },
      {
        path: "leagues",
        name: "My Leagues",
        meta: { requiredAuth: true },
        component: () => import("../views/account/leagues/MyLeagues.vue"),
      },
      {
        path: "games",
        name: "My Games",
        meta: { requiredAuth: true },
        component: () => import("../views/account/games/Games.vue"),
      },
      {
        path: "teams",
        name: "My Teams",
        meta: { requiredAuth: true },
        component: () => import("../views/account/teams/MyTeams.vue"),
      },
      {
        path: "transactions",
        name: "My Transactions",
        meta: { requiredAuth: true },
        component: () =>
          import("../views/account/transactions/Transactions.vue"),
      },
    ],
  },
  {
    path: "/rankings",
    name: "Rankings",
    component: () => import("../views/rankings/Rankings.vue"),
  },
  {
    path: "/news",
    name: "News",
    component: () => import("../views/news/News.vue"),
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/about/About.vue"),
  },
  {
    path: "/streams",
    name: "Streams",
    component: () => import("../views/streams/Streams.vue"),
  },
  {
    path: "/games",
    name: "Games",
    component: () => import("../views/games/Games.vue"),
  },
  {
    path: "/leagues",
    name: "Leagues",
    component: () => import("../views/leagues/Leagues.vue"),
  },
  {
    path: "/league-details/:id",
    component: () => import("../views/leagueDetails/LeagueDetails.vue"),
    children: [
      {
        path: "",
        alias: "general",
        name: "League General",
        component: () => import("../views/leagueDetails/children/General.vue"),
      },
      {
        path: "table",
        name: "League Table",
        component: () => import("../views/leagueDetails/children/Table.vue"),
      },
      {
        path: "teams",
        name: "League Teams",
        component: () => import("../views/leagueDetails/children/Teams.vue"),
      },
      {
        path: "participants",
        name: "League Participants",
        component: () =>
          import("../views/leagueDetails/children/Participants.vue"),
      },
      {
        path: "rules",
        name: "League Rules",
        component: () => import("../views/leagueDetails/children/Rules.vue"),
      },
    ],
  },
  {
    path: "/teams",
    component: () => import("../views/teams/Teams.vue"),
    children: [
      {
        path: "",
        name: "Teams",
        component: () => import("../views/teams/children/Teams.vue"),
      },
      {
        path: "new",
        name: "New Team",
        meta: { requiredAuth: true },
        component: () => import("../views/teams/children/New.vue"),
      },
    ],
  },
  {
    path: "/team-details/:id",
    name: "Team Details",
    meta: { requiredAuth: true },
    component: () => import("../views/teamDetails/TeamDetails.vue"),
  },
  {
    path: "/invite/:code",
    name: "Invite",
    meta: { requiredAuth: true },
    component: () => import("../views/invite/Invite.vue"),
  },
  {
    path: "/sponsors",
    name: "Sponsors",
    component: () => import("../views/sponsors/Sponsors.vue"),
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  base: `${process.env.BASE_URL}`,
  routes,
});

router.beforeEach(beforeEach);

export default router;
