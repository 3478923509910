import store from "../store";

export default async (to, from, next) => {
  // settings
  document.title = to.name + " - Liga Estudantil Paraibana";
  if (to.name !== from.name) window.scrollTo(0, 0);

  // set auth token
  if (to.query.tk) store.dispatch("setToken", to.query.tk);
  if (to.query.rtk) store.dispatch("setRefreshToken", to.query.rtk);

  // handle auth state
  const requiredAuth = to.meta.requiredAuth || false;
  const preventAuth = to.meta.preventAuth || false;
  const authenticated = !!store.getters.token;

  // if the user has not authenticated and tries access private page
  if (requiredAuth && !authenticated) {
    const url = `${store.getters.authURL}/auth/?callback_url=${store.getters.appURL}`;
    window.open(url, "_self");
  }

  // if the user has authenticated and tries access prevent authenticated page
  if (preventAuth && authenticated) {
    return next("/main");
  }

  // allow navigate
  next();
};
