<template>
  <v-hover v-model="hover">
    <v-btn
      v-on="$listeners"
      class="rounded-lg"
      :class="hover ? 'primary' : 'transparent'"
      plain
      icon
      @click="submit()"
    >
      <v-icon small> mdi-arrow-left </v-icon>
    </v-btn>
  </v-hover>
</template>

<script>
export default {
  data() {
    return {
      hover: false,
    };
  },

  methods: {
    submit() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped></style>
