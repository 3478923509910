<template>
  <div class="d-flex flex-column altback">
    <!-- pre-footer -->
    <div class="d-flex flex-column flex-md-row justify-space-around py-6">
      <!-- col 1 -->
      <div class="d-flex flex-column px-2">
        <div class="d-flex mb-6">
          <v-img
            max-width="96"
            height="96"
            contain
            :src="require('@/assets/logo/logo.png')"
          />

          <div class="d-flex flex-column align-center ma-2">
            <v-btn v-text="'Parceiros'" class="justify-start" text />

            <v-divider class="my-2" />

            <v-responsive max-width="128">
              <v-img :src="require('@/assets/utils/arena.png')" contain />
            </v-responsive>
          </div>
        </div>

        <v-btn
          v-for="(item, index) of rules"
          v-text="item.text"
          class="justify-start mb-2"
          target="_blank"
          :key="index"
          :href="item.url"
          text
        />
      </div>

      <!-- col 2 -->
      <div class="d-flex flex-column px-2 mb-6">
        <v-btn
          v-for="(item, i) of routes"
          class="justify-start mb-2"
          :color="!item.path ? 'primary' : 'overtext'"
          :text="!!item.path"
          :key="i"
          @click="navigation(item)"
        >
          {{ item.label }}
        </v-btn>
      </div>

      <!-- col 3 -->
      <div class="d-flex flex-column px-2 mb-6">
        <div
          v-for="(item, index) of contact"
          :key="index"
          class="d-flex mb-2"
          style="max-width: 270px"
        >
          <v-icon v-text="item.icon" color="primary" left />

          <span v-text="item.text" />
        </div>
      </div>

      <!-- col 4 -->
      <div class="d-flex flex-column px-2 mb-6">
        <div class="d-flex align-center mb-2">
          <v-icon v-text="'$hammer'" left large />
          <span class="text-body-1">Sobre nós</span>
        </div>

        <span class="mb-6 text-body-1" style="max-width: 320px">
          A Liga Escolar Paraibana de E-Sports (LEPE) será administrada e
          organizada pela própria LEPE juntamente com a Arena Jogue fácil.
        </span>

        <v-btn color="primary" to="/about" outlined> Mais sobre nós </v-btn>
      </div>
    </div>

    <!-- footer -->
    <div class="d-flex justify-center" style="margin-bottom: -4px">
      <img :src="require('@/assets/utils/triangle.svg')" max-width="128" />
    </div>

    <v-footer
      color="primary"
      class="d-flex flex-column flex-md-row justify-space-between py-6 px-4"
    >
      <div class="d-md-flex flex-md-column mb-4 mb-md-0">
        <v-btn
          v-for="(item, index) of social"
          class="justify-start"
          target="_blank"
          :href="item.url"
          :key="index"
          text
        >
          <v-icon left v-text="item.icon" />
          <span v-text="item.text" />
        </v-btn>
      </div>

      <div class="text-center mb-4 mb-md-0">
        <span
          v-text="
            `© ${new Date().getFullYear()} ARENA Jogue Fácil INC. Todos os direitos reservados.`
          "
        />
      </div>

      <v-btn large rounded color="white" @click="handleScrollUp()">
        <v-icon large color="black" v-text="'mdi-chevron-up'" />
      </v-btn>
    </v-footer>
  </div>
</template>

<script>
import { getContact, getSocial } from "@/services/social";

export default {
  data() {
    return {
      social: [],
      contact: [],
      rules: [
        {
          text: "Regulamento Geral",
          url: "https://drive.google.com/file/d/1JPV-QRKpRqoj49NiwKYjQVrI4gPnuao8/view?usp=sharing",
        },
        {
          text: "Pré-regulamento",
          url: "https://drive.google.com/file/d/1J2UFdR0Qh6EuSy6--MxJ3QxrQy05a6L5/view?usp=sharing",
        },
      ],
      routes: [
        { label: "Home", path: "/home" },
        { label: "Sobre", path: "/about" },
        { label: "Parceiros", path: "/partners" },
        { label: "Participe" },
      ],
    };
  },

  beforeMount() {
    this.getData();
  },

  methods: {
    getData() {
      this.getSocial();
      this.getContact();
    },

    async getSocial() {
      try {
        await getSocial().then((res) => (this.social = res.data));
      } catch (err) {
        // err
      }
    },

    async getContact() {
      try {
        await getContact().then((res) => {
          this.contact = [
            { icon: "mdi-email", text: res.data.email },
            { icon: "mdi-phone", text: res.data.phone.formatted },
            { icon: "mdi-map-marker", text: res.data.address },
          ];
        });
      } catch (err) {
        // err
      }
    },

    navigation(item) {
      if (!item.path) {
        if (this.$store.getters.authenticated)
          this.$router.push({ path: "/leagues" });
        else this.$emit("login");
      }

      if (this.$route.path === item.path) return this.handleScrollUp();

      this.$router.push(item.path);
    },

    handleScrollUp() {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    },
  },
};
</script>

<style></style>
