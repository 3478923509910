<template>
  <div class="d-flex">
    <v-card
      class="rounded-0 pr-md-4"
      color="primary"
      height="44px"
      :to="localUser ? '/account/profile' : '/register'"
    >
      <div class="d-flex align-center fill-height">
        <v-img
          :src="
            !!localUser && localUser.avatar
              ? localUser.avatar
              : require('@/assets/utils/profile.png')
          "
          class="mr-md-4 accent"
          height="44"
          width="44"
        />

        <span v-if="$vuetify.breakpoint.mdAndUp">
          {{ user.firstName }}
        </span>
      </div>
    </v-card>

    <v-menu class="rounded-l-0 rounded-r-lg px-1" bottom offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-card
          class="rounded-l-0 rounded-r-lg px-1"
          color="background"
          height="44px"
          link
          v-bind="attrs"
          v-on="on"
        >
          <v-icon
            class="fill-height"
            color="primary"
            v-text="'mdi-menu-down'"
          />
        </v-card>
      </template>

      <v-list>
        <v-list-item v-for="(item, index) in menu" :key="index">
          <div class="d-flex align-center flex-grow-1">
            <v-btn
              class="flex-grow-1 justify-start"
              text
              @click="$router.push(item.path)"
            >
              <v-icon left v-text="item.icon" />
              <span v-text="item.title" />
            </v-btn>
          </div>
        </v-list-item>

        <v-divider />

        <!--Logout-->
        <v-list-item>
          <v-btn text @click="exit()">
            <v-icon left v-text="'mdi-logout'" />
            <span v-text="'Finalizar Sessão'" />
          </v-btn>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      menu: [
        { title: "Minha conta", path: "/account/profile", icon: "mdi-account" },
        { title: "Minhas ligas", path: "/account/leagues", icon: "mdi-shield" },
        {
          title: "Minhas Equipes",
          path: "/account/teams",
          icon: "mdi-account-multiple",
        },
        { title: "Meus Jogos", path: "/account/games", icon: "mdi-gamepad" },
      ],
    };
  },

  computed: {
    ...mapState(["user", "localUser"]),
  },

  methods: {
    async exit() {
      this.$root.$emit("logoff");
    },
  },
};
</script>

<style></style>
