import Vue from "vue";
import Vuetify from "vuetify";
import _colors from "@/styles/_colors.scss";
import "vuetify/dist/vuetify.min.css";
import pt from "vuetify/lib/locale/pt";
import hammer from "./custom-icons/hammer";
import hammer2 from "./custom-icons/hammer2";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,

    options: {
      customProperties: true,
    },

    themes: {
      dark: {
        ..._colors,
        primary: "#FF4655",
        secondary: "#FF1A2C",
        info: "#2196F3",
        success: "#4CAF50",
        error: "#FF5252",
        warning: "#FFC107",
        background: "#16181B",
        altback: "#0B0B11",
        surface: "#1F2125",
        accent: "#2e3036",
        overtext: "#FFFFFF",
        subtext: "#9b9ca2",
      },
    },
  },

  lang: {
    locales: { pt },
    current: "pt",
  },

  icons: {
    values: {
      hammer: {
        component: hammer,
      },
      hammer2: {
        component: hammer2,
      },
    },
  },
});
