export const getSocial = () =>
  Promise.resolve({
    data: [
      {
        name: "instagram",
        text: "Instagram",
        icon: "mdi-instagram",
        color: "#B330B1",
        url: "https://instagram.com/ligaestudantilpb",
      },
      {
        name: "discord",
        text: "Discord",
        icon: "mdi-discord",
        color: "#6263F0",
        url: "https://discord.gg/9yGaQNFeGK",
      },
      {
        name: "twitch",
        text: "Twitch",
        icon: "mdi-twitch",
        color: "#7313B7",
        url: "https://www.twitch.tv/ajfesports",
      },
      {
        name: "youtube",
        text: "Youtube",
        icon: "mdi-youtube",
        color: "#F4271C",
        url: "https://youtube.com/channel/UC1JYqg4U5Afznq5vGyJaJPA",
      },
    ],
  });

export const getContact = () =>
  Promise.resolve({
    data: {
      phone: {
        number: "5583994198419",
        formatted: "(83) 99419-8419",
        whatsapp: "5583994198419",
      },
      email: "liga@arenajoguefacil.com.br",
      address: "Avenida Prof. maria Sales, 261 Tambaú, João Pessoa - PB",
    },
  });
