var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column fill-height"},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-system-bar',{staticClass:"px-4 px-md-12",attrs:{"height":"48","color":"background","app":""}},[_c('v-spacer'),(_vm.$vuetify.breakpoint.mdAndUp)?_c('span',{staticClass:"saira"},[_vm._v(" Redes Sociais ")]):_vm._e(),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-divider',{style:({
        'max-width': '50px',
        backgroundColor: 'white',
        marginLeft: '8px',
      })}):_vm._e(),_vm._l((_vm.social),function(item,index){return _c('v-btn',{key:index,staticClass:"rounded-lg mx-1",style:({ backgroundColor: item.color }),attrs:{"target":"_blank","href":item.url,"small":"","icon":""}},[_c('v-icon',{attrs:{"small":"","color":"white"},domProps:{"textContent":_vm._s(item.icon)}})],1)})],2):_vm._e(),_c('v-app-bar',{staticClass:"px-md-16",attrs:{"id":"nav","height":"80","color":"altback","dense":"","flat":"","app":""}},[_c('v-responsive',{attrs:{"max-width":_vm.$vuetify.breakpoint.mdAndUp ? '6rem' : '4rem'}},[_c('router-link',{attrs:{"to":"/"}},[_c('v-img',{attrs:{"src":require('@/assets/logo/logo.png')}})],1)],1),_c('v-spacer'),(_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"d-flex"},_vm._l((_vm._routes),function(item,i){return _c('v-btn',{key:i,staticClass:"text-none mx-2",attrs:{"to":item.path,"disabled":item.disabled,"plain":"","color":i === _vm._selectedRoute ? 'primary' : ''}},[_c('span',{staticClass:"saira font-weight-bold"},[_vm._v(" "+_vm._s(item.label.toUpperCase())+" ")])])}),1):_c('div',{staticClass:"d-flex justify-center"},[_c('v-menu',{attrs:{"content-class":"fill-width mt-2","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"rounded-lg surface",attrs:{"width":"36","height":"36","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-menu")])],1)]}}])},[_c('v-list',{staticClass:"accent fill-width"},_vm._l((_vm._routes),function(item,i){return _c('router-link',{key:i,attrs:{"to":item.path,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive }){return [_c('v-list-item',{attrs:{"href":href},on:{"click":navigate}},[_c('v-list-item-title',{staticClass:"text-button",class:isActive
                    ? 'primary--text font-weight-bold'
                    : 'overtext--text'},[_vm._v(" "+_vm._s(item.label)+" ")])],1)]}}],null,true)})}),1)],1)],1),_c('v-spacer'),(!_vm.authenticated)?_c('v-btn',{staticClass:"rounded-l-0 rounded-r-lg text-none",attrs:{"color":"primary"},on:{"click":function($event){_vm.loginDialog = true}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-outline")]),_c('span',[_vm._v("Entre "+_vm._s(_vm.$vuetify.breakpoint.mdAndUp ? "ou cadastre-se" : ""))])],1):_c('div',{staticClass:"d-flex"},[_c('AccountMenu')],1)],1),_c('v-main',{staticClass:"background"},[_vm._t("default")],2),_c('AppFooter',{on:{"login":function($event){return _vm.handleParticipate()}}}),_c('v-btn',{attrs:{"fab":"","fixed":"","bottom":"","right":"","color":"#25D366"},on:{"click":function($event){return _vm.handleOpenSocial(_vm.whatsapp.url)}}},[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-whatsapp')}})],1),_c('v-dialog',{attrs:{"max-width":"900"},model:{value:(_vm.loginDialog),callback:function ($$v) {_vm.loginDialog=$$v},expression:"loginDialog"}},[(_vm.loginDialog)?_c('AuthCard',{on:{"close":function($event){_vm.loginDialog = false}}}):_vm._e()],1),_c('v-dialog',{attrs:{"max-width":"900"},model:{value:(_vm.popupDialog),callback:function ($$v) {_vm.popupDialog=$$v},expression:"popupDialog"}},[_c('v-card',{staticClass:"pa-4",attrs:{"color":"surface"}},[_c('v-img',{attrs:{"src":require('@/assets/home/popup.jpeg')}},[_c('v-btn',{attrs:{"icon":"","absolute":"","top":"","right":""},on:{"click":function($event){_vm.popupDialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }