<template>
  <div class="d-flex flex-column align-center justify-center">
    <v-progress-circular :size="32" color="primary" indeterminate />
  </div>
</template>

<script>
export default {};
</script>

<style></style>
