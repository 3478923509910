<template>
  <v-container class="background" style="height: 100vh">
    <div class="d-flex justify-center align-center py-16">
      <v-progress-circular :size="64" color="primary" indeterminate />
    </div>
  </v-container>
</template>

<script>
export default {};
</script>

<style></style>
