<template>
  <div class="d-flex flex-column fill-height">
    <!-- toolbar -->
    <v-system-bar
      v-if="$vuetify.breakpoint.mdAndUp"
      class="px-4 px-md-12"
      height="48"
      color="background"
      app
    >
      <v-spacer />

      <span class="saira" v-if="$vuetify.breakpoint.mdAndUp">
        Redes Sociais
      </span>

      <v-divider
        v-if="$vuetify.breakpoint.mdAndUp"
        :style="{
          'max-width': '50px',
          backgroundColor: 'white',
          marginLeft: '8px',
        }"
      />

      <v-btn
        v-for="(item, index) of social"
        class="rounded-lg mx-1"
        target="_blank"
        :style="{ backgroundColor: item.color }"
        :href="item.url"
        :key="index"
        small
        icon
      >
        <v-icon small color="white" v-text="item.icon" />
      </v-btn>
    </v-system-bar>

    <!-- app bar -->
    <v-app-bar
      id="nav"
      class="px-md-16"
      height="80"
      color="altback"
      dense
      flat
      app
    >
      <v-responsive :max-width="$vuetify.breakpoint.mdAndUp ? '6rem' : '4rem'">
        <router-link to="/">
          <v-img :src="require('@/assets/logo/logo.png')" />
        </router-link>
      </v-responsive>

      <v-spacer />

      <!-- desktop nav -->
      <div v-if="$vuetify.breakpoint.mdAndUp" class="d-flex">
        <v-btn
          v-for="(item, i) in _routes"
          class="text-none mx-2"
          :key="i"
          :to="item.path"
          :disabled="item.disabled"
          plain
          :color="i === _selectedRoute ? 'primary' : ''"
        >
          <span class="saira font-weight-bold">
            {{ item.label.toUpperCase() }}
          </span>
        </v-btn>
      </div>

      <!-- mobile nav -->
      <div v-else class="d-flex justify-center">
        <v-menu content-class="fill-width mt-2" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              class="rounded-lg surface"
              width="36"
              height="36"
              icon
            >
              <v-icon>mdi-menu</v-icon>
            </v-btn>
          </template>

          <v-list class="accent fill-width">
            <router-link
              v-for="(item, i) in _routes"
              v-slot="{ href, navigate, isActive }"
              :to="item.path"
              :key="i"
              custom
            >
              <v-list-item :href="href" @click="navigate">
                <v-list-item-title
                  class="text-button"
                  :class="
                    isActive
                      ? 'primary--text font-weight-bold'
                      : 'overtext--text'
                  "
                >
                  {{ item.label }}
                </v-list-item-title>
              </v-list-item>
            </router-link>
          </v-list>
        </v-menu>
      </div>

      <v-spacer />

      <v-btn
        v-if="!authenticated"
        class="rounded-l-0 rounded-r-lg text-none"
        color="primary"
        @click="loginDialog = true"
      >
        <v-icon left>mdi-account-outline</v-icon>

        <span
          >Entre {{ $vuetify.breakpoint.mdAndUp ? "ou cadastre-se" : "" }}</span
        >
      </v-btn>

      <div v-else class="d-flex">
        <AccountMenu />
      </div>
    </v-app-bar>

    <!-- slot view -->
    <v-main class="background">
      <slot />
    </v-main>

    <!-- footer -->
    <AppFooter @login="handleParticipate()" />

    <!--Whatsapp Button-->
    <v-btn
      fab
      fixed
      bottom
      right
      color="#25D366"
      @click="handleOpenSocial(whatsapp.url)"
    >
      <v-icon v-text="'mdi-whatsapp'" />
    </v-btn>

    <v-dialog v-model="loginDialog" max-width="900">
      <AuthCard v-if="loginDialog" @close="loginDialog = false" />
    </v-dialog>

    <v-dialog v-model="popupDialog" max-width="900">
      <v-card color="surface" class="pa-4">
        <v-img :src="require('@/assets/home/popup.jpeg')">
          <v-btn icon absolute top right @click="popupDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-img>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { loadSession } from "@/services/session.js";
import { getGames } from "@/services/user";
import AccountMenu from "@/components/app/AccountMenu.vue";
import AuthCard from "@/components/app/AuthCard.vue";
import AppFooter from "@/components/app/AppFooter";
import { getSocial, getContact } from "@/services/social";

export default {
  name: "ApplicationComponent",
  components: {
    AccountMenu,
    AppFooter,
    AuthCard,
  },

  data() {
    return {
      loginDialog: false,
      popupDialog: true,
      authRoutes: [
        { path: "/main", label: "Início" },
        { path: "/news", label: "Notícias" },
        { path: "/leagues", label: "Ligas" },
        { path: "/teams", label: "Equipes" },
        { path: "/games", label: "Jogos" },
        { path: "/sponsors", label: "Patrocinadores" },
      ],
      routes: [
        { path: "/home", label: "Início" },
        { path: "/news", label: "Notícias" },
        { path: "/leagues", label: "Ligas" },
        { path: "/teams", label: "Equipes" },
        { path: "/sponsors", label: "Patrocinadores" },
        { path: "/about", label: "Sobre" },
      ],
      social: [],
      whatsapp: {
        url: "",
        phone: "",
        text: "",
      },
    };
  },

  beforeMount() {
    getSocial().then(({ data }) => (this.social = data));
    getContact().then(({ data }) => {
      const url = "https://api.whatsapp.com/send?1=pt_br";
      const phone = data.phone.whatsapp;
      const text = "";
      this.whatsapp.phone = phone;
      this.whatsapp.text = text;
      this.whatsapp.url = `${url}&phone=${phone}&text=${text}`;
    });

    this.$root.$on("refresh-user", () => {
      this.getUser();
    });

    this.getGames();
  },

  beforeDestroy() {
    this.$root.$off("refresh-user");
  },

  computed: {
    ...mapState(["user", "localUser"]),
    ...mapGetters(["authenticated"]),

    _selectedRoute: function () {
      return this._routes.findIndex((e) => this.$route.path === e.path);
    },
    _routes: function () {
      return this.authenticated ? this.authRoutes : this.routes;
    },
  },

  methods: {
    ...mapActions(["setUser", "setLocalUser", "setModalities"]),

    async getUser() {
      try {
        await loadSession().then((res) => {
          this.setUser(res.foreign);
          this.setLocalUser(res.local);
        });
      } catch (err) {
        // err
      }
    },

    async getGames() {
      try {
        await getGames().then((res) => {
          this.setModalities(res.games);
        });
      } catch (err) {
        // err
      }
    },

    handleOpenSocial(url) {
      window.open(url, "_blank").focus();
    },

    handleParticipate() {
      if (!this.authenticated) return (this.loginDialog = true);
      if (this.$route.path === "/leagues") return this.handlePageUp();

      return this.$router.push({ path: "/leagues" });
    },

    handleGoTo(path) {
      this.handlePageUp();

      // Home ~ Main
      if (path == "/") {
        if (["/main", "/home", "/"].includes(this.$route.path)) return;
        return this.$router.push("/");
      }

      // Other Routes (Check if already)
      if (this.$route.path != path) this.$router.push(path);
    },

    handlePageUp() {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    },
  },
};
</script>

<style lang="scss" scoped>
:deep() .v-system-bar .v-icon {
  margin-right: 0px !important;
}
</style>
