import axios from "axios";
import store from "@/store";

export const api = axios.create({
  baseURL: store.getters.apiURL,
  timeout: 8000,
});

export const authAPI = axios.create({
  baseURL: store.getters.apiAuthURL,
  timeout: 8000,
});

// request
export async function request(method, url, data) {
  return await new Promise((resolve, reject) => {
    try {
      url = url.replaceAll("undefined", "").replaceAll("null", "");
      api[method](url, data)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err.response));
    } catch (err) {
      return reject(err);
    }
  });
}

// request2
export async function request2(method, url, data) {
  return await new Promise((resolve, reject) => {
    try {
      url = url.replaceAll("undefined", "").replaceAll("null", "");
      authAPI[method](url, data)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err.response));
    } catch (err) {
      return reject(err);
    }
  });
}

// interceptor
api.interceptors.response.use(
  (res) => Promise.resolve(res),
  (err) => responseFailed(err)
);

const responseFailed = (err) => {
  // server error
  if (
    (err === "Error: Network Error" || err.response.status >= 500) &&
    !!store.getters.token
  )
    return signOutLocal(err);

  // unauthorized
  if ([401, 403].includes(err.response.status)) {
    const refreshToken = store.getters.refreshToken;

    // Previne infinity sign-out requests
    const ignoredRoutes = ["/user/refresh-token", "/user/sign-out"];
    if (ignoredRoutes.includes(err.config.url) || !refreshToken) {
      return signOutLocal(err);
    }

    // Try RefreshToken and resend request
    return authAPI
      .post("/user/refresh-token", { refreshToken })
      .then(({ data }) => {
        store.dispatch("setToken", data.accessToken);
        store.dispatch("setRefreshToken", data.refreshToken);

        // 'err.config' is a Original Request
        err.config.headers.Authorization = data.accessToken;

        // Resend Request
        const reqResent = axios(err.config);
        return Promise.resolve(reqResent);
      })
      .catch(() => signOutLocal(err));
  }

  return Promise.reject(err);
};

function signOutLocal(err) {
  store.dispatch("setSignOut");
  return Promise.reject(err);
}
