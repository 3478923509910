import { request, request2 } from "./index";

export const sendUserData = (data) => request("post", "/users", data);

export const updateUserData = (data) => request("put", "/users", data);

export const getGames = () => request("get", "/games");

export const getUserGames = () => request("get", "/users/player-data");

export const saveUserGame = (data) =>
  request("post", "/users/player-data", data);

export const updateUserGame = (data) =>
  request("put", `users/player-data/${data.id}`, data.body);

export const getSchools = (data) => request("get", `/schools/${data}`);

export const updateUser = (data) => request2("put", "/user", data);

export const changeProof = (data) =>
  request("post", "/users/append/school-certificate", data);
