import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";
import { api, authAPI } from "@/services";

Vue.use(Vuex);

const vuexLocal = new VuexPersist({
  key: "localStorage",
  storage: window.localStorage, // persistent on localstorage
  modules: ["token", "refreshToken"], // persistent variable on state
});

export default new Vuex.Store({
  state: {
    appURL: "https://liga.arenajoguefacil.com.br", // "http://localhost:8080",
    apiURL: "https://liga.arenajoguefacil.com.br/api", // "http://localhost:4000",
    apiAuthURL: "https://arenajoguefacil.com.br/api",
    authURL: "https://auth.arenajoguefacil.com.br/#",
    token: null,
    refreshToken: null,
    user: null,
    localUser: null,
    selected: null,
    modalities: [],
  },

  getters: {
    appURL: ({ appURL }) => appURL,
    apiURL: ({ apiURL }) => apiURL,
    authURL: ({ authURL }) => authURL,
    apiAuthURL: ({ apiAuthURL }) => apiAuthURL,
    authenticated: ({ token, user }) => !!token && !!user,
    token: ({ token }) => token,
    refreshToken: ({ refreshToken }) => refreshToken,
    user: ({ user }) => user,
    localUser: ({ localUser }) => localUser,
    selected: ({ selected }) => selected,
    modalities: ({ modalities }) => modalities,
  },

  mutations: {
    SET_TOKEN(state, payload) {
      state.token = payload;
    },

    SET_REFRESH_TOKEN(state, payload) {
      state.refreshToken = payload;
    },

    SET_USER(state, payload) {
      state.user = payload;
    },

    SET_LOCAL_USER(state, payload) {
      state.localUser = payload;
    },

    SET_SELECTED(state, payload) {
      state.selected = payload;
    },

    SET_MODALITIES(state, payload) {
      state.modalities = payload;
    },
  },

  actions: {
    setSignIn({ dispatch }, payload) {
      dispatch("setUser", payload.user);
      dispatch("setLocalUser", payload.localUser);
    },

    setToken({ commit }, payload) {
      commit("SET_TOKEN", payload);

      // set header authorization
      api.defaults.headers.common.Authorization = payload;
      authAPI.defaults.headers.common.Authorization = payload;
    },

    setRefreshToken({ commit }, payload) {
      commit("SET_REFRESH_TOKEN", payload);
    },

    setUser({ commit }, payload) {
      commit("SET_USER", payload);
    },

    setLocalUser({ commit }, payload) {
      commit("SET_LOCAL_USER", payload);
    },

    setSelected({ commit }, payload) {
      commit("SET_SELECTED", payload);
    },

    setModalities({ commit }, payload) {
      commit("SET_MODALITIES", payload);
    },

    setSignOut({ dispatch }) {
      dispatch("setToken", null);
      dispatch("setRefreshToken", null);
      dispatch("setUser", null);
    },
  },

  plugins: [vuexLocal.plugin],

  modules: {},
});
