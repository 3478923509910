<template>
  <v-card class="pa-6 pa-md-12" color="altback">
    <div class="d-flex flex-column fill-height">
      <div class="d-flex justify-end mb-4 mb-md-0">
        <v-btn color="primary" outlined icon @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <div class="d-flex justify-center mb-8 text-center">
        <v-img
          v-if="$vuetify.breakpoint.smAndUp"
          :src="require('@/assets/utils/divider.png')"
          class="mr-4"
          max-width="6vw"
          contain
        />

        <span class="text-h5 text-sm-h4 font-weight-bold">
          <span class="saira">Cadastre-se para participar</span>
        </span>

        <v-img
          v-if="$vuetify.breakpoint.smAndUp"
          :src="require('@/assets/utils/divider.png')"
          class="ml-4"
          max-width="6vw"
          style="transform: scaleX(-1)"
          contain
        />
      </div>

      <v-row justify="space-around">
        <v-col cols="12" md="5" class="d-flex flex-column text-center">
          <span
            class="mb-8 subtext--text text-body-1 text-sm-h6 font-weight-regular"
          >
            Ainda não tenho uma conta <br v-if="$vuetify.breakpoint.mdAndUp" />
            Arena jogue fácil
          </span>

          <v-btn
            class="rounded-l-0 rounded-r-lg"
            color="white"
            light
            x-large
            @click="signUp()"
          >
            Criar conta
          </v-btn>
        </v-col>

        <v-col cols="12" md="5" class="d-flex flex-column text-center">
          <span
            class="mb-8 subtext--text text-body-1 text-sm-h6 font-weight-regular"
          >
            Tenho uma conta <br v-if="$vuetify.breakpoint.mdAndUp" />
            Arena Jogue Fácil
          </span>

          <v-btn
            class="rounded-l-0 rounded-r-lg"
            color="primary"
            x-large
            @click="signIn()"
          >
            Efetuar acesso
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
export default {
  methods: {
    signUp() {
      const url = `${
        this.$store.getters.authURL
      }/auth/sign-up?callback_url=${encodeURIComponent(
        this.$store.getters.appURL + "/home"
      )}`;
      window.open(url, "_self");
    },

    signIn() {
      const url = `${
        this.$store.getters.authURL
      }/auth?callback_url=${encodeURIComponent(
        this.$store.getters.appURL + "/home"
      )}`;
      window.open(url, "_self");
    },

    close() {
      this.$emit("close");
    },
  },
};
</script>

<style></style>
