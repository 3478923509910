<template>
  <v-app id="app">
    <SplashScreen v-if="loading" />

    <Application ref="application" v-else>
      <router-view @login="handleLoginDialog" />
    </Application>

    <SnackBar />
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
import { api, authAPI } from "./services";
import { signOut } from "@/services/auth";
import { loadSession } from "./services/session.js";
import Application from "./template/Application.vue";
import SplashScreen from "./template/SplashScreen.vue";
import SnackBar from "./components/app/SnackBar.vue";

export default {
  name: "App",

  data() {
    return {
      loading: true,
    };
  },

  components: {
    Application,
    SplashScreen,
    SnackBar,
  },

  beforeMount() {
    this.startSession();

    this.$root.$on("loading", (data) => {
      this.loading = data;
    });

    this.$root.$on("logoff", () => {
      this.logoff();
    });
  },

  beforeDestroy() {
    this.$root.$off("loading");
    this.$root.$off("logoff");
  },

  watch: {
    [`$route`]() {
      if (this.$store.getters.authenticated && !this.$store.getters.localUser)
        this.$router.push({ path: "/register" }).catch(() => {
          /* err  */
        });
    },
  },

  methods: {
    ...mapActions(["setSignIn", "setSignOut"]),

    handleLoginDialog() {
      this.$refs.application.loginDialog = true;
    },

    // checks if the token is still valid for a session
    async startSession() {
      const token = this.$store.getters.token;

      if (token) {
        api.defaults.headers.common.Authorization = token;
        authAPI.defaults.headers.common.Authorization = token;

        try {
          await loadSession().then((res) => {
            this.setSignIn({
              user: res.foreign,
              localUser: res.local,
            });

            if (!res.local) {
              this.$router.push({ path: "/register" }).catch(() => {
                /* err  */
              });
            }
          });
        } catch (err) {
          this.setSignOut();
        }
      }

      setTimeout(() => {
        this.loading = false;
      }, 1000);
    },

    async logoff() {
      try {
        this.loading = true;

        if (this.$store.getters.token) {
          await signOut();
        }
      } catch (err) {
        // err
      } finally {
        await this.setSignOut();
        await this.$router.push({ path: "/home" });
        this.loading = false;
      }
    },
  },
};
</script>

<style src="./styles/style.scss" lang="scss" />
