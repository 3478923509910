<template>
  <v-dialog v-model="dialog" :max-width="width" :persistent="persistent">
    <v-card color="surface">
      <div v-if="!hideHeader" class="d-flex py-2 px-8 mb-4 accent">
        <div class="d-flex column">
          <div class="d-flex align-center">
            <v-icon v-text="'$hammer2'" left />

            <h1 class="text-h5 font-weight-bold">
              <span class="saira"> {{ title }}</span>
            </h1>
          </div>

          <h2 v-if="!!subtitle" class="text-body-2 subtext--text">
            {{ subtitle }}
          </h2>
        </div>

        <v-spacer />

        <v-btn v-if="!lockClose" class="rounded-lg" icon @click="closeDialog()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <section class="px-8 py-2">
        <slot />
      </section>

      <div v-if="!hideFooter" class="d-flex justify-end py-4 px-8 mt-2">
        <v-btn
          v-if="!lockClose"
          class="rounded-r-0 rounded-l-lg text-none mr-2"
          color="surface"
          width="96"
          depressed
          @click="closeDialog()"
        >
          Cancelar
        </v-btn>

        <v-btn
          class="rounded-l-0 rounded-r-lg text-none"
          color="primary"
          width="96"
          @click="submit()"
        >
          Confirmar
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
    };
  },

  props: {
    title: {
      type: String,
      default: "",
    },

    subtitle: {
      type: String,
      default: "",
    },

    width: {
      type: String,
      default: "600",
    },

    persistent: {
      type: Boolean,
      default: false,
    },

    lockClose: {
      type: Boolean,
      default: false,
    },

    hideHeader: {
      type: Boolean,
      default: false,
    },

    hideFooter: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    submit() {
      this.$emit("submit");
    },

    openDialog() {
      this.dialog = true;
    },

    closeDialog() {
      this.dialog = false;
    },
  },
};
</script>

<style></style>
